[data-amplify-theme] {
  /* main button; reset password text */
  --amplify-components-button-primary-background-color: #1976d2;
  --amplify-components-button-link-color: #1976d2;
}

[data-amplify-authenticator] {
  height: 100%;
}

:root {
  --amplify-primary-color: #11a29b;
  --amplify-primary-tint: #3f6060;
  --amplify-primary-shade: #2dba89;
  --amplify-background-color: #e0faf4;
}