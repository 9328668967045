@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 0.4;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
}